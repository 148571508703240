import React from "react";
import { Link } from "gatsby";
import * as styles from "../../styles/checkout/checkout.module.css";

import CartList from "../CartList";

import { useDispatch, useSelector } from "react-redux";
import CheckoutForm from "./CheckoutForm";

function CashFree() {
  const operationState = useSelector((state) => state.operations);
  return (
    <React.Fragment>
      {!operationState.operating && (
        <p
          style={{
            padding: "1rem",
            maxWidth: "85ch",
            lineHeight: "1.6",
            whiteSpace: "pre-wrap",
            color: "red",
            textAlign: "center",
          }}
        >
          WE ARE NOT OPERATING TODAY! <br />
          <br />{" "}
          <Link
            style={{
              color: "white",
              background: "#8a0d10",
              padding: ".5rem",
            }}
            to="/contact"
          >
            Contact us
          </Link>{" "}
          directly for further enquiries.
        </p>
      )}

      <div className={styles.container}>
        {/* Formik Checkout form */}

        <CheckoutForm operationState={operationState} />
        <div className={styles.cart}>
          <CartList hideCheckout={true} />
        </div>
      </div>

      {/* CashFree Checkout Form */}
    </React.Fragment>
  );
}

export default CashFree;
