import React from "react";
import Logo from "./SVG/Logo";
import * as styles from "../styles/loading.module.css";

function Loading({ text, Svg, fixed = true }) {
  return (
    <div
      style={
        !fixed
          ? {
              position: "relative",
              zIndex: 1,
              background: "radial-gradient(#710a0e, black)",
            }
          : { position: "fixed" }
      }
      className={styles.processingOverlay}
    >
      <div style={{ position: "relative" }}>
        {!Svg && <div className={styles.animation}></div>}
        {Svg ? <Svg /> : <Logo />}
      </div>
      <h3>{text}</h3>
    </div>
  );
}

export default Loading;
