import React, { useRef, useEffect } from "react";
import Layout from "../components/layout";
import CheckoutContent from "../components/checkout";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import {STARTED_CHECKOUT} from '../events'

function Checkout() {
  const paymentState = useSelector((state) => state.payment);

  const cashfreeFormRef = useRef();

  useEffect(() => {
    //Send the list to google analytics
    window
      .analytics
      .logEvent(STARTED_CHECKOUT);
  }, []);
  useEffect(() => {
    if (paymentState.Redirect) {
      cashfreeFormRef.current.submit();
    }
  }, [paymentState.Redirect]);

  return (
    <Layout
      title="checkout page"
      description="Thank you for purchasing with N.K. Caterers! We hope you had a wonderful experience"
    >
      {paymentState.loading && <Loading text="Request is being processed" />}
      <CheckoutContent />
      <form
        ref={cashfreeFormRef}
        method="post"
        action={`${process.env.GATSBY_CASHFREE_POST}`}
      >
        <input
          type="hidden"
          name="appId"
          value={paymentState.PaymentData.appId}
        />
        <input
          type="hidden"
          name="orderId"
          value={paymentState.PaymentData.orderId}
        />
        <input
          type="hidden"
          name="orderAmount"
          value={paymentState.PaymentData.orderAmount}
        />
        <input
          type="hidden"
          name="orderCurrency"
          value={paymentState.PaymentData.orderCurrency}
        />
        <input
          type="hidden"
          name="customerName"
          value={paymentState.PaymentData.customerName}
        />
        <input
          type="hidden"
          name="customerEmail"
          value={paymentState.PaymentData.customerEmail}
        />
        <input
          type="hidden"
          name="customerPhone"
          value={paymentState.PaymentData.customerPhone}
        />
        <input
          type="hidden"
          name="returnUrl"
          value={paymentState.PaymentData.returnUrl}
        />
        <input
          type="hidden"
          name="signature"
          value={paymentState.PaymentData.signature}
        />
      </form>
    </Layout>
  );
}

export default Checkout;
