import React, { useState } from "react";

import * as styles from "../../styles/checkout/checkout.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SubmitCheckoutForm } from "../../Redux/actions/PaymentActions";
import { checkCookie } from "../../storageHelper/Cookie";

const GetCurrentTime = () => {
  var today = new Date(); //Todays date
  //Return current time in minutes
  return parseInt(today.getHours() * 60) + parseInt(today.getMinutes()); //Current time in minutes from 12am
};

const checkTodaySelected = (date) => {
  var todayDate = new Date(); //Current Date
  todayDate.setHours(0, 0, 0, 0);
  var selectedDate = new Date(date); //Form date
  selectedDate.setHours(0, 0, 0, 0);
  if (todayDate.getTime() === selectedDate.getTime()) {
    return true;
  } else {
    return false;
  }
};

function CheckoutForm({ operationState }) {
  const [deliveryInfo, setDeliveryInfo] = useState({
    today: false,
    accepting: true,
  });

  const cartState = useSelector((state) => state.cart);
  //Check if cart has any products
  const hasProducts = !(
    cartState.CartProducts.length === 0 &&
    Object.keys(cartState.MenuProducts).length === 0
  );
  const dispatch = useDispatch();

  // //Parse the input field time and convert it into minutes from 12am
  const getSelectedTime = (time) => {
    var splitTime = time.split(":");
    var hours = splitTime[0];
    var minutes = splitTime[1];
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  // //Check if the date is past
  function checkPast(date) {
    if (date === undefined) {
      return false;
    }
    var todayDate = new Date(); //Current Date
    todayDate.setHours(0, 0, 0, 0);
    var selectedDate = new Date(date); //Form date
    selectedDate.setHours(0, 0, 0, 0);
    if (todayDate.getTime() > selectedDate.getTime()) {
      setDeliveryInfo((state) => {
        return { ...state, today: false };
      });
      return false;
    } else {
      //Past date is not selected
      return true;
    }
  }

  // //Check if order is placed during working hours
  function checkTodayAccepting(date) {
    if (date == undefined) {
      return false;
    } else if (checkTodaySelected(date)) {
      //If it is today
      setDeliveryInfo((state) => {
        return {
          ...state,
          today: true,
        };
      });
      const currentTime = GetCurrentTime();
      //Check if order is being placed in between opening and closing time
      if (
        currentTime > operationState.openTime &&
        currentTime < operationState.closeTime
      ) {
        //Order is placed at working hours
        return true;
      } else {
        return false;
      }
    } else {
      //If today is not selected this condition is passed
      setDeliveryInfo((state) => {
        return { ...state, today: false };
      });
      return true;
    }
  }

  // //Check if the date is present, and if so make sure number of guests can be served
  function checkPresentQuantity(date) {
    //If today is selected
    if (date === undefined) {
      return false;
    } else if (deliveryInfo.today) {
      //If today is selected
      var numberOfGuests = 0;
      //Loop through all the items added and find the largest number of guests for an item
      cartState.CartProducts.forEach((item) => {
        if (item.guests > numberOfGuests) {
          numberOfGuests = item.guests;
        }
      });
      //If today is selected as delivery date
      if (
        cartState.MenuProducts.guests > operationState.MaxGuestsServed ||
        numberOfGuests > operationState.MaxGuestsServed
      ) {
        //Check if the number of guests to be served exceeds the amount that is set for same day delivery
        return false;
      }
      //If the number of guests can be served for today delivery
      else {
        return true;
      }
      //If Today is not selected
    } else {
      return true;
    }
  }

  // //Check if delivery date is today, can the delivery be made at the selected time
  function checkPreparationTime(time) {
    //Make sure the selected delivery time gives enough working time
    //(i.e.) SelectedTime - currentTime >= PreparationTime
    if (deliveryInfo.today && time !== undefined) {
      //If today is selected for delivery
      var currentTime = GetCurrentTime(); //Current Time in minutes
      //Check if 6 hours are available to make the delivery
      var timeSelected = getSelectedTime(time); //Time selected for delivery
      var workingHours = timeSelected - currentTime; //Time we get to preapre the food depending upon users selection
      if (operationState.preparationTime <= workingHours) {
        return true;
      } else {
        return false;
      }
    }
    //If delivery is not for today then don't need to check anything
    else {
      return true;
    }
  }
  // //Check if the delivery exceeds last delivery time
  function checkLastTime(time) {
    //Make sure selected delivery time is <= LastestDeliveryBy
    if (time !== undefined) {
      var timeSelected = getSelectedTime(time); //Time selected for delivery
      if (timeSelected <= operationState.LastestDeliveryBy) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  //Check if the delivery is before first delivery time
  function checkEarliestTime(time) {
    //Make sure selected delivery time is >= EarliestDeliveryBy
    if (time !== undefined) {
      var timeSelected = getSelectedTime(time); //Time selected for delivery
      if (timeSelected >= operationState.EarliestDeliveryBy) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const handleSubmit = (values, setSubmitting) => {
    //If cookie is not there do nothing
    if (!checkCookie("sessionValid")) {
      alert("Your session has expired! Please Reload the page!");
    } else {
      // Get the data from the Formik form and put it into the format requried
      const formData = {
        orderId: `${new Date().getTime()}`,
        customerName: values.firstName + " " + values.lastName,
        customerPhone: values.Pnumber,
        customerEmail: values.email,
        orderAmount: `${cartState.CartTotal + cartState.MenuTotal}`,
        orderCurrency: "INR",
        // Not needed for Cashfree
        address1: values.Add1,
        address2: values.Add2,
        address3: values.Add3,
        landMark: values.landMark,
        date: values.date,
        time: values.time,
      };
      //To set the PaymentData and the MessageData using the form input data
      dispatch(SubmitCheckoutForm(formData));
      // setSubmitting(false);
    }
  };

  const Validation = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    Pnumber: Yup.string()
      .required("Required")
      .min(10)
      .max(10)
      .matches(/^\d+$/, "Phone number is not valid"),
    date: Yup.string()
      .required("Required")
      .test("checkPast", "Cannot input past date", checkPast) //Check for past date
      .test(
        "checkTodayAccepting",
        "We are not accepting orders today! Please select a different delivery date.",
        checkTodayAccepting
      ) //Check if order placed today, if so within working hours
      .test(
        "checkPresentQuantity",
        `The quantity of food selected cannot be delivered today! Please choose a later date for delivery, or reduce the quantity to serve at most ${operationState.MaxGuestsServed} guests.`,
        checkPresentQuantity
      ), //Check if order is placed for today then if the quantity can be delivered

    time: Yup.string()
      .required("Required")
      .test(
        "checkPreparationTime",
        `We require more time to prepare your menu for delivery today. Fastest delivery by ${
          new Date().getHours() + 6
        }:${new Date().getMinutes()}.`,
        checkPreparationTime
      )
      .test(
        "checkLastTime",
        `We offer last delivery by ${operationState.LastestDeliveryByFormatted}`,
        checkLastTime
      )
      .test(
        "checkEarliestTime",
        `We offer earliest delivery by ${operationState.EarliestDeliveryByFormatted}`,
        checkEarliestTime
      ),
    Add1: Yup.string().required("Required"),
    landMark: Yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        Pnumber: "",
        Add1: "",
        Add2: "",
        Add3: "",
        landMark: "",
        date: "",
        time: "",
      }}
      validationSchema={Validation}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting)
      }
    >
      {({ isSubmitting }) => {
        return (
          <Form
            className={`${styles.form} ${
              !operationState.operating && styles.disable
            }`}
          >
            <div className={styles.formRow}>
              <div>
                <label className={styles.formLabel} htmlFor="firstName">
                  First Name
                </label>
                <Field
                  className={styles.formField}
                  placeholder="Jane"
                  type="text"
                  name="firstName"
                />
                <ErrorMessage
                  className={styles.formError}
                  name="firstName"
                  component="div"
                />
              </div>
              <div>
                <label className={styles.formLabel} htmlFor="lastName">
                  Last Name
                </label>
                <Field
                  className={styles.formField}
                  placeholder="Doe"
                  type="text"
                  name="lastName"
                />
                <ErrorMessage
                  className={styles.formError}
                  name="lastName"
                  component="div"
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div>
               
                <label className={`${styles.formLabel} ${styles.number}`} htmlFor="Pnumber">
                  Phone Number
                </label>
                <Field
                  className={styles.formField}
                  placeholder="9008755620"
                  type="text"
                  name="Pnumber"
                />
                <ErrorMessage
                  className={styles.formError}
                  name="Pnumber"
                  component="div"
                />
              </div>

              <div>
                <label className={styles.formLabel} htmlFor="email">
                  Email
                </label>
                <Field
                  className={styles.formField}
                  placeholder="jane@example.com"
                  type="email"
                  name="email"
                />
                <ErrorMessage
                  className={styles.formError}
                  name="email"
                  component="div"
                />
              </div>
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="Add1">
                Address Line 1
              </label>
              <Field
                className={styles.formField}
                placeholder="Second floor, Example apartment"
                type="text"
                name="Add1"
              />
              <ErrorMessage
                className={styles.formError}
                name="Add1"
                component="div"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="Add2">
                Address Line 2
              </label>
              <Field
                className={styles.formField}
                placeholder="#2 hutchins road"
                type="text"
                name="Add2"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="Add3">
                Address Line 3
              </label>
              <Field
                className={styles.formField}
                placeholder="Cox town"
                type="text"
                name="Add3"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="landMark">
                Landmark
              </label>
              <Field
                className={styles.formField}
                placeholder="Opposite Mantri Square"
                type="text"
                name="landMark"
              />
              <ErrorMessage
                className={styles.formError}
                name="landMark"
                component="div"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="date">
                Delivery Date
              </label>
              <Field
                className={styles.formField}
                placeholder="Opposite Mantri Square"
                type="date"
                name="date"
              />
              <ErrorMessage
                className={styles.formError}
                name="date"
                component="div"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="time">
                Delivery Time
              </label>
              <Field
                className={styles.formField}
                placeholder="Opposite Mantri Square"
                type="time"
                name="time"
              />
              <ErrorMessage
                className={styles.formError}
                name="time"
                component="div"
              />
            </div>
            {/* If there are no CartProducts and no MenuProducts then disable the submit button */}
            <button
              className={hasProducts ? styles.button : styles.disabledButton}
              type="submit"
            >
              {hasProducts ? "Submit" : "No Products in cart"}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CheckoutForm;
